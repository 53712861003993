.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("https://images.unsplash.com/photo-1605792657660-596af9009e82?q=80&w=2902&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.formContainer {
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.title {
  text-align: center;
  color: #333;
}

.form {
  margin-top: 20px;
}

.inputGroup {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
  color: #666;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.errorInput {
  border-color: #e74c3c;
}

.error {
  color: #e74c3c;
  font-size: 0.875em;
  margin-top: -5px;
  margin-bottom: 10px;
}

.button {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 1em;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

.labelCheckbox {
  margin-left: 8px;
  font-size: 0.875em;
  color: #666;
}
