body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  border-collapse: collapse;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.investment-table{
  width: 90%;
}

.users-table{
  width: 30%;
}

th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}

th {
  background-color: #007bff;
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
  border: 1px solid;
}

select {
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

button {
  padding: 4px 6px;
  margin-left: 8px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

button:hover {
  background-color: #0056b3;
}

/* Additional styling to ensure consistency and visual appeal */
table select, table button {
  vertical-align: middle;
}

.left-sidebar {
  background-color: #f0f0f0;
  height: 100vh; 
  width: 200px; 
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
}
.sidebar-link {
  font-family: "Source Sans Pro", sans-serif; 
  font-size: 16px;
  font-weight: 400; /* Regular weight */
  color: #333; /* Adjust color as needed */
  text-decoration: none; /* Remove underline from links */
}

ul li{
  list-style: none;
}
